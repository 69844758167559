import React, { useEffect, useRef } from "react"
import { Col, Container, Row } from "reactstrap"

import { ReactComponent as Email } from "../assets/contact/email.svg"
import { ReactComponent as Facebook } from "../assets/contact/facebook.svg"
import { ReactComponent as Hashtag } from "../assets/contact/hashtag.svg"
import { ReactComponent as Instagram } from "../assets/contact/instagram.svg"
import { ReactComponent as Phone } from "../assets/contact/telephone.svg"
import { ReactComponent as Web } from "../assets/contact/web.svg"
import { useNavigation } from "../providers/NavigationProvider"

export default function Contact() {
  const { setActive } = useNavigation()
  const pageRef = useRef({})

  const itemList = [
    { Icon: Phone, label: "0905 822 163" },
    { Icon: Instagram, label: "shishatime_zilina" },
    { Icon: Facebook, label: "Shishatime Žilina" },
    { Icon: Email, label: "za@shishatime.sk" },
    { Icon: Web, label: "www.shishatime.sk" },
    { Icon: Hashtag, label: "#shishatimeza" },
  ]

  const hoursList = [
    { label: "pondelok - štvrtok", hours: "17:00 - 22:30" },
    { label: "piatok - nedeľa", hours: "12:00 - 00:00" },
    // { label: "nedeľa", hours: "12:00 - 22:00" },
  ]

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling)
    return () => {
      document.removeEventListener("scroll", trackScrolling)
    }
  })

  function isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 150
  }
  function trackScrolling() {
    if (isBottom(pageRef.current)) {
      setActive("contact")
    }
  }

  return (
    <div id="contact" ref={pageRef}>
      <Container>
        <Row style={{ paddingTop: 25, paddingBottom: 25 }}>
          <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {itemList.map(({ Icon, label }) => (
              <div
                key={label}
                style={{
                  display: "flex",
                  paddingBottom: 20,
                }}
              >
                <span className="iconWrapper">
                  <Icon width={40} height={40} />
                  <p style={{ paddingLeft: 10 }}>{label}</p>
                </span>
              </div>
            ))}
          </Col>
          <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <h2 style={{ textAlign: "center" }}>Rozvoz</h2>
            {hoursList.map(({ label, hours }) => (
              <div key={label} style={{ paddingBottom: 15 }}>
                <p className="label" style={{ textTransform: "uppercase", textAlign: "center" }}>
                  {label}
                </p>
                <p style={{ textAlign: "center" }}>{hours}</p>
              </div>
            ))}
            {/* <h2 style={{ textAlign: "center", paddingTop: 30 }}>Smer klub 77</h2>
            <div style={{ paddingBottom: 15 }}>
              <p className="label" style={{ textTransform: "uppercase", textAlign: "center" }}>
                Piatok
              </p>
              <p style={{ textAlign: "center" }}>18:00 - 02:00</p>
            </div>
            <div style={{ paddingBottom: 15 }}>
              <p className="label" style={{ textTransform: "uppercase", textAlign: "center" }}>
                Štvrtok, Sobota
              </p>
              <p style={{ textAlign: "center" }}>Na rezerváciu</p>
            </div> */}
          </Col>
          <Col>
            <div
              className="map-root-wrapper"
              style={{
                position: "relative",
                textAlign: "right",
                margin: "0 auto",
              }}
            >
              <div
                className="map-second-wrapper"
                style={{
                  overflow: "hidden",
                  background: "none !important",
                }}
              >
                <iframe
                  className="map"
                  title="googlemap"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=shishatimezilina&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <p style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5, borderTop: "1px solid grey" }}>
        ©2022 Shishatime
      </p>
    </div>
  )
}
