import React, { useEffect, useState } from "react"
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, NavbarToggler } from "reactstrap"

import logo from "../assets/logo.png"
import { useNavigation } from "../providers/NavigationProvider"
import OutsideAlerter from "./helper/OutsideAlerter"

export default function AppNavbar() {
  const { active, setActive } = useNavigation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <OutsideAlerter onClickOutside={() => setIsOpen(false)}>
      <Navbar color="dark" dark expand="md" fixed="top" container="lg">
        <NavbarBrand href="#reservation">
          <img className="logo" src={logo} alt="shishatime" width={100} height={100} />
        </NavbarBrand>
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar style={{ justifyContent: "flex-end" }}>
          <Nav navbar>
            <NavItem>
              <NavLink
                className={active === "reservation" ? "active" : ""}
                // onClick={() => setActive("reservation")}
                href="#reservation"
                onClick={() => setIsOpen(false)}
              >
                Rezervácia
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active === "about" ? "active" : ""}
                // onClick={() => setActive("about")}
                href="#about-anchor"
                onClick={() => setIsOpen(false)}
              >
                Chill zóna
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active === "contact" ? "active" : ""}
                // onClick={() => setActive("contact")}
                href="#contact"
                onClick={() => setIsOpen(false)}
              >
                Kontakt
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </OutsideAlerter>
  )
}
