import React from "react"
import { Button, Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap"
import TimePicker from "react-bootstrap-time-picker"
import Lottie from "react-lottie"

import { isEmailValid } from "../utils/validation"
import CalendarInput from "../components/CalendarInput"
import { getSeconds, getTimeString } from "../utils/general"
import SmokeLottie from "../assets/smoke.json"

export default function DeliveryForm({ delivery, setDelivery, handleSubmit, sendingEmail, emailSent, emailError }) {
  function handleChange(value, prop) {
    setDelivery({ ...delivery, [prop]: value })
  }

  function handleChangeTime(newTime) {
    setDelivery({ ...delivery, time: getTimeString(newTime) })
  }

  return (
    <Form>
      <Row>
        <Col xs={6} md={4}>
          <FormGroup>
            <Label for="delivTaste">Príchuť</Label>
            <Input id="delivTaste" type="select" onChange={({ target: { value } }) => handleChange(value, "taste")}>
              <option>Sladká</option>
              <option>Svieža</option>
              <option>Citrusová</option>
              <option>Netradičná</option>
            </Input>

            <FormText color="white" style={{ fontStyle: "italic" }}>
              Shishaman ťa bude kontaktovať pre upresnenie
            </FormText>
          </FormGroup>
        </Col>
        <Col xs={6} md={4}>
          <FormGroup>
            <Label for="delivExtraCrown">Extra korunka navyše</Label>
            <Input
              id="delivExtraCrown"
              type="select"
              onChange={({ target: { value } }) => handleChange(value, "extra")}
            >
              <option>Áno</option>
              <option>Nie</option>
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup style={{ position: "relative" }}>
            <Label>Dátum</Label>
            <CalendarInput
              locale="sk"
              onChange={(value) => setDelivery({ ...delivery, date: value })}
              value={delivery.date}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Čas</Label>
            <TimePicker
              start="12:00"
              end="23:00"
              step={15}
              value={getSeconds(delivery.time)}
              format={24}
              onChange={handleChangeTime}
              style={{ cursor: "pointer" }}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="delivAddress">Adresa dovozu *</Label>
            <Input
              id="delivAddress"
              valid={!!delivery.address}
              onChange={({ target: { value } }) => handleChange(value, "address")}
              type="delivAddress"
              value={delivery.address}
            />
          </FormGroup>
        </Col>
        {/* <Col>
          <FormGroup>
            <Label for="delivCity">Mesto / Obec</Label>
            <Input
              id="delivCity"
              valid={delivery.city}
              onChange={({ target: { value } }) => handleChange(value, "city")}
              type="text"
              value={delivery.city}
            />
          </FormGroup>
        </Col> */}
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="delivName">Meno a priezvisko *</Label>
            <Input
              id="delivName"
              onChange={({ target: { value } }) => handleChange(value, "name")}
              type="text"
              valid={!!delivery.name}
              value={delivery.name}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="delivPhone">Telefón *</Label>
            <Input
              id="delivPhone"
              type="tel"
              onChange={({ target: { value } }) => handleChange(value, "phone")}
              valid={!!delivery.phone && !isNaN(delivery.phone)}
            />
            {/* shows feedback if the input is valid */}
            {/* <FormFeedback valid>Sweet! that name is available</FormFeedback> */}
            {/* shows input help text */}
            {/* <FormText>Shishaman vas kontaktuje po uzavreni objednavky.</FormText> */}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="delivEmail">Email *</Label>
            <Input
              id="delivEmail"
              valid={isEmailValid(delivery.email)}
              onChange={({ target: { value } }) => handleChange(value, "email")}
              type="email"
              value={delivery.email}
            />
            <FormText color="white" style={{ fontStyle: "italic" }}>
              Pre zhrnutie tvojej objednávky
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="delivNote">Poznámka</Label>
            <Input
              id="delivNote"
              onChange={({ target: { value } }) => handleChange(value, "note")}
              type="textarea"
              value={delivery.note}
            />
          </FormGroup>
        </Col>
      </Row>

      <div style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }}>
        <p>* Vyplň prosím všetky povinné polia</p>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {emailError ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ padding: 10 }}>
              Niečo sa pokazilo pri odosielaní objednávky. Skús to prosím znova alebo nás kontaktuj telefonicky.
            </p>
            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Button
                style={{ width: 130, marginRight: 40 }}
                className="button"
                onClick={() => window.location.reload()}
              >
                Skúsiť znova
              </Button>
              <Button className="button" onClick={() => (window.location = "tel:+421905822163")}>
                Zavolať
              </Button>
            </div>
          </div>
        ) : emailSent ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ padding: 10 }}>
              Tvoja objednávka na rozvoz bola odoslaná. Jej zhrnutie sme ti poslali do emailu.
            </p>
            <Button className="button" onClick={() => window.location.reload()}>
              Ok
            </Button>
          </div>
        ) : sendingEmail ? (
          <Lottie options={{ loop: true, autoplay: true, animationData: SmokeLottie }} height={50} width={100} />
        ) : (
          <Button
            className="button"
            onClick={handleSubmit}
            disabled={
              !(
                delivery.address &&
                delivery.name &&
                !isNaN(delivery.phone) &&
                delivery.phone &&
                isEmailValid(delivery.email)
              )
            }
          >
            Odoslať
          </Button>
        )}
      </div>
    </Form>
  )
}
