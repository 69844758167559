import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAY70e9N11qu9h4z6TxU8JomYXJvvHbTdk",
  authDomain: "shishatime-1172d.firebaseapp.com",
  projectId: "shishatime-1172d",
  storageBucket: "shishatime-1172d.appspot.com",
  messagingSenderId: "16596508974",
  appId: "1:16596508974:web:44b3ae4316d3b5267f5abf",
}

const firebase = initializeApp(firebaseConfig)

export default { firebase }
