import { createContext, useContext, useState } from "react"

const NavigationContext = createContext()

export function useNavigation() {
  const { active, setActive } = useContext(NavigationContext)

  return { active, setActive }
}

export default function NavigationProvider(props) {
  const [active, setActive] = useState("reservation")

  return <NavigationContext.Provider value={{ active, setActive }}>{props.children}</NavigationContext.Provider>
}
