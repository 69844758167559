import axios from "axios"
import { firebase } from "../firebase/config"

export async function sendReservationEmail(type, data) {
  // const token = firebase.

  try {
    const response = await axios.post(
      "https://europe-west3-shishatime-1172d.cloudfunctions.net/sendReservationEmail",
      // "http://localhost:5001/demo-shishatime/europe-west3/sendReservationEmail",
      JSON.stringify({ type, data: data }),
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
    console.log("RESPONSE", response)
    return response
  } catch (error) {
    console.error("Error sending reservation email: ", error)
    throw error
  }
}
