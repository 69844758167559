import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { sendReservationEmail } from "../actions/sendReservationEmail"

import DeliveryForm from "../forms/DeliveryForm"
import RestaurantForm from "../forms/RestaurantForm"
import { useNavigation } from "../providers/NavigationProvider"

export default function Reservation() {
  const [type, setType] = useState("DELIVERY")
  const { setActive } = useNavigation()
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const today = new Date()
  const [delivery, setDelivery] = useState({
    date: `${String(today.getDate()).padStart(2, "0")}/${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}/${today.getFullYear()}`,
    taste: "Sladká",
    extra: "Áno",
    time: "13:00",
    address: "",
    name: "",
    phone: "",
    email: "",
    note: "",
  })
  const [reservation, setReservation] = useState({
    date: `${String(today.getDate()).padStart(2, "0")}/${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}/${today.getFullYear()}`,
    time: "13:00",
    name: "",
    phone: "",
    email: "",
    note: "",
  })

  console.debug("DELIVERY", delivery)
  console.debug("RESTAURANT", reservation)

  async function handleSubmit() {
    try {
      setSendingEmail(true)
      const response = await sendReservationEmail(type, type === "DELIVERY" ? delivery : reservation)

      setTimeout(() => {
        if (response.data?.status === "SUCCESS" && response.status === 200) {
          setSendingEmail(false)
          setEmailSent(true)
        } else {
          setEmailError(true)
        }
      }, 500)
    } catch (error) {
      console.error("Error sending email: ", error.message)
      setEmailError(true)
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling)
  })

  function trackScrolling() {
    // setTimeout(() => { // this delay is setting about after the contact is selected
    if (window.pageYOffset <= window.innerHeight / 2) {
      setActive("reservation")
    } else if (
      window.pageYOffset > window.innerHeight / 2 &&
      window.pageYOffset <= window.innerHeight + window.innerHeight / 2
    ) {
      setActive("about")
    }
    // }, 300)
  }

  return (
    <div id="reservation" className={type === "RESTAURANT" ? "restaurant" : "delivery"}>
      <Container>
        <div className="typeWrapper">
          {/* <h2 className={`${type === "RESTAURANT" ? "active" : ""} switch-item`} onClick={() => setType("RESTAURANT")}>
            Smer klub 77
          </h2> */}
          <h2 className={`${type === "DELIVERY" ? "active" : ""} switch-item`} onClick={() => setType("DELIVERY")}>
            Rozvoz
          </h2>
        </div>
        {type === "DELIVERY" && (
          <DeliveryForm
            handleSubmit={handleSubmit}
            delivery={delivery}
            setDelivery={setDelivery}
            sendingEmail={sendingEmail}
            emailSent={emailSent}
            emailError={emailError}
          />
        )}
        {/* {type === "RESTAURANT" && (
          <RestaurantForm
            handleSubmit={handleSubmit}
            reservation={reservation}
            setReservation={setReservation}
            sendingEmail={sendingEmail}
            emailSent={emailSent}
            emailError={emailError}
          />
        )} */}
      </Container>
    </div>
  )
}
