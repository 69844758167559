import React, { useState } from "react"
import { Input } from "reactstrap"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { sk, enUS, es } from "date-fns/locale"

import OutsideAlerter from "./helper/OutsideAlerter"

registerLocale("sk", sk)
registerLocale("en", enUS)
registerLocale("es", es)

export default function CalendarInput({ value, onChange, locale }) {
  const [calendarVisible, setCalendarVisible] = useState(false)
  function handleChangeDate(newDate) {
    onChange(
      `${String(newDate.getDate()).padStart(2, "0")}/${String(newDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${newDate.getFullYear()}`
    )
    setCalendarVisible(!calendarVisible)
  }

  return (
    <OutsideAlerter onClickOutside={() => setCalendarVisible(false)}>
      <Input
        style={{ cursor: "pointer" }}
        id="delivDate"
        type="button"
        value={value}
        onClick={() => setCalendarVisible(!calendarVisible)}
      />
      <div style={{ position: "absolute", display: calendarVisible ? "block" : "none" }}>
        <DatePicker locale={locale} onChange={handleChangeDate} inline calendarStartDay={1} />
      </div>
    </OutsideAlerter>
  )
}
