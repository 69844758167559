import "./styles/index.scss"
import AppNavbar from "./components/AppNavbar"
import Reservation from "./pages/Reservation"
import About from "./pages/About"
import Contact from "./pages/Contact"
import NavigationProvider from "./providers/NavigationProvider"

function App() {
  return (
    <div className="App">
      <NavigationProvider>
        <AppNavbar />
        <Reservation />
        <About />
        <Contact />
      </NavigationProvider>
    </div>
  )
}

export default App
