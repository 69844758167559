// hh:mm string
export function getSeconds(timeString) {
  const hours = Number(timeString.split(":")[0])
  const minutes = Number(timeString.split(":")[1])

  return hours * 3600 + minutes * 60
}

export function getTimeString(time) {
  return new Date(time * 1000).toISOString().substring(11, 16).split("-").join(":")
}
