import React, { useEffect, useRef } from "react"
import { Button, Col, Container, Row } from "reactstrap"

import shishaChill from "../assets/chillzone-1.jpg"
import smerShisha from "../assets/chillzone-2.jpg"
import meal from "../assets/chillzone-3.jpg"
import { useNavigation } from "../providers/NavigationProvider"

export default function About() {
  const { setActive } = useNavigation()
  const pageRef = useRef({})

  return (
    <div id="about" ref={pageRef}>
      <a id="about-anchor"></a>
      <Container>
        <h1 style={{ textAlign: "center" }}>Chill zóna</h1>
        <Row style={{ alignItems: "center" }}>
          <Col style={{ display: "flex", justifyContent: "right" }}>
            <img alt="shisha chill" src={shishaChill} />
          </Col>
          <Col>
            <p style={{ textAlign: "justify", maxWidth: 500 }}>
              Vynikajúce koktaily, drinky, PS4 a vodné fajky. Ako lepšie sa dá stráviť piatkový večer ako s kamarátmi
              pri vynikajúcej vodnej fajke a profesionálne namiešaných alkoholických, ale aj nealkoholických nápojov. Od
              teraz nás môžeš takmer každý víkend navštíviť v UPSIDE DOWN coctail bare v Považskej Bystrici. (Sleduj
              naše stories na IG a zisti viac)
            </p>
          </Col>
        </Row>
        <Row className="row2" style={{ alignItems: "center" }}>
          <Col style={{ display: "flex", justifyContent: "right" }}>
            <p style={{ textAlign: "justify", maxWidth: 500 }}>
              Náš profesionálne školený personál sa o vás bude starať s úsmevom na tvári po celú dobu fajčenia.
              Pravidelná výmena uhlíkov, ktorá prebieha presne každých 20 minút a veľký výber príchutí z vysoko
              kvalitných tabakov popredných svetových značiek vám zaručí pôžitok z fajčenia, aký ste ešte nezažili!
            </p>
          </Col>
          <Col style={{ display: "flex", justifyContent: "left" }}>
            <img alt="shisha chill" src={smerShisha} />
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col style={{ display: "flex", justifyContent: "right" }}>
            <img alt="shisha chill" src={meal} />
          </Col>
          <Col>
            <p style={{ textAlign: "justify", maxWidth: 500 }}>
              Vaša spokojnosť je naša najvyššia priorita. Pokiaľ máš rád zábavu a príjemnú spoločnosť, tak je čas
              povedať už len jedno...
            </p>
          </Col>
        </Row>
        <Row className="row4" style={{ display: "flex", justifyContent: "center" }}>
          <h2 style={{ textAlign: "center", paddingBottom: 15 }}>Time for Shisha! 💨</h2>
          <Button
            className="button"
            onClick={(e) => {
              e.preventDefault()
              window.location.href = "#reservation"
              setActive("reservation")
            }}
          >
            Rezervovať
          </Button>
        </Row>
      </Container>
    </div>
  )
}
